var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s12" }, [
        _vm.testing
          ? _c("div", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Getting active tokens...")
            ])
          : _c("div", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Currently active tokens:")
            ]),
        _vm._v(" "),
        _vm.status === 0 ? _c("div", [_vm._v("...")]) : _vm._e(),
        _vm._v(" "),
        _vm.status === 1
          ? _c(
              "div",
              { staticStyle: { color: "green", "font-weight": "bold" } },
              [_vm._v("SUCCESS")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.status === -1
          ? _c(
              "div",
              { staticStyle: { color: "red", "font-weight": "bold" } },
              [_vm._v("ERROR")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.message
          ? _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } })
          : _vm._e()
      ]),
      _vm._v(" "),
      this.tokens
        ? _c(
            "div",
            { staticClass: "col s12", staticStyle: { "margin-top": "10px" } },
            _vm._l(this.tokens, function(t) {
              return _c(
                "div",
                { key: t.value, staticStyle: { display: "flex" } },
                [
                  _c(
                    "div",
                    { staticStyle: { flex: "1 1 10%", margin: "0px 5px" } },
                    [
                      t.lastActiveInMinutes < 60 && !t.expired
                        ? _c(
                            "span",
                            {
                              staticClass: "btn-small red pulse ",
                              staticStyle: {
                                overflow: "visible",
                                "text-align": "center",
                                "min-width": "100px"
                              }
                            },
                            [_vm._v(_vm._s(t.lastActiveInMinutes) + "m")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      t.lastActiveInMinutes >= 60 && !t.expired
                        ? _c(
                            "span",
                            {
                              staticClass: "btn-small cyan ",
                              staticStyle: {
                                overflow: "visible",
                                "text-align": "center",
                                "min-width": "100px"
                              }
                            },
                            [_vm._v(_vm._s(t.lastActiveInHours) + "h")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      t.expired
                        ? _c(
                            "span",
                            {
                              staticClass: "btn-small grey ",
                              staticStyle: {
                                overflow: "visible",
                                "text-align": "center",
                                "min-width": "100px"
                              }
                            },
                            [_vm._v("x")]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { flex: "1 1 40%", margin: "0px 5px" } },
                    [
                      _vm._v(_vm._s(t.value) + " "),
                      _c("strong", [_vm._v("(" + _vm._s(t.email) + ")")])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { flex: "1 1 30%", margin: "0px 5px" } },
                    [
                      t.expired
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("Expired ")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !t.expired
                        ? _c("span", { staticStyle: { color: "darkgreen" } }, [
                            _vm._v("Will expire in ")
                          ])
                        : _vm._e(),
                      _vm._v(
                        "\r\n                    " +
                          _vm._s(_vm._f("toDuration")(t.minutesDiff)) +
                          "\r\n                    "
                      ),
                      t.expired
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(" ago ")
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { flex: "1 1 20%" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.onRemoveToken(t)
                          }
                        }
                      },
                      [_vm._v("remove")]
                    ),
                    _vm._v(" |\r\n                    "),
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.onRefreshToken(t)
                          }
                        }
                      },
                      [_vm._v("refresh")]
                    )
                  ])
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col s12" }, [
        _c(
          "button",
          { staticClass: "btn right", on: { click: _vm.onRefresh } },
          [_vm._v("Refresh")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col s12" }, [
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "switch" }, [
            _c("label", [
              _vm._v(
                "\r\n                    Auto update\r\n                    "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.autoUpdate,
                    expression: "autoUpdate"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.autoUpdate)
                    ? _vm._i(_vm.autoUpdate, null) > -1
                    : _vm.autoUpdate
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.autoUpdate,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.autoUpdate = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.autoUpdate = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.autoUpdate = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "lever" }),
              _vm._v("\r\n                    -\r\n                    ")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _vm._v(
            "Last update: " + _vm._s(_vm._f("toDateString")(_vm.lastUpdate))
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }