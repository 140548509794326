import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const guestUser = {
  isGuest: true,
};

const store = new Vuex.Store({
  state: {
    user: guestUser,
    adminEditPageId:undefined
  },
  mutations: {
    setAdminEditPageId(state, id) {
      state.adminEditPageId = id;
    },
    setUser(state, user) {
        if (!user)
            user=guestUser;
      state.user = user;
    },
  },
  actions: {
  },
});

export default store;
