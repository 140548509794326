<template>
<div>
  <db-status></db-status>  
  <active-tokens></active-tokens>
</div>

</template>

<style lang="less" scoped>

</style>

<script>
import dbStatus from '../components/dbStatus.vue';
import activeTokens from '../components/activeTokens.vue';

export default {
  components:{
    dbStatus,
    activeTokens
  },
  data () {
    return {
    }
  },
  mounted(){
    console.log('sf');
  }
}
</script>