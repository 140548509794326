<template>
<div class="container">
    <div class="row">
            <div class="col s12">
                User Id: {{id}} <br/><br/>
            </div>
            <div class="input-field col s12">
                <input id="realName" type="text" class="validate" v-model="realName">
                <label for="realName" class="active">Name</label>
            </div>
            <div class="input-field col s12">
                <input id="organization" type="text" class="validate" v-model="organization" :disabled="disableOrganization">
                <label for="organization" class="active">Organization</label>
            </div>
            <div class="input-field col s12">
                <input id="group" type="text" class="validate" v-model="group">
                <label for="group" class="active">Group</label>
            </div>
            <div class="input-field col s12">
                <input id="email" type="email" class="validate" v-model="email">
                <label for="email" class="active">Email</label>
            </div>
            <div class="input-field col s12">
                <input id="password" type="text" class="validate" v-model="password">
                <label for="password" class="active">Password</label>
            </div>
            <div class="input-field col s12 m2">
                <img :src="imgURL" style="width:80%" @error="onImgError"/>
            </div>
            <div class="input-field col s12 m10">
                <input id="imgURL" type="text" class="validate" v-model="imgURL">
                <label for="imgURL" class="active">Image URL (700x600)</label>
            </div>
            <form class="col s12"  method="post" @submit.prevent="upload('img'+id)" encType="multipart/form-data">
                <input :id="'img'+id" name="foo" type="file" />
                <input type="submit">
            </form>
            <div class="input-field col s12">
                <input id="bio" type="text" class="validate" v-model="bio">
                <label for="bio" class="active">Bio</label>
            </div>
            <!-- <div class="input-field col s12">
                <input id="characterName" type="text" class="validate" v-model="characterName">
                <label for="characterName" class="active">Character name</label>
            </div> -->
            <div class="col s3">
                <div class="switch">
                    <label>
                    Writer
                    <input class="red" type="checkbox" v-model="isWriter">
                    <span class="lever"></span>
                    -
                </label>
                </div>
            </div>
            <div class="col s3">
                <div class="switch">
                    <label>
                    Admin
                    <input type="checkbox" v-model="isAdmin">
                    <span class="lever"></span>
                    -
                </label>
                </div>
            </div>
            <div class="col s3">
                <div class="switch">
                    <label>
                    Can purchase books
                    <input type="checkbox" v-model="canPurchaseBooks">
                    <span class="lever"></span>
                    -
                </label>
                </div>
            </div>
            <div class="col s3">
                <div class="switch">
                    <label>
                    Can add characters
                    <input type="checkbox" v-model="canAddCharacters">
                    <span class="lever"></span>
                    -
                </label>
                </div>
            </div>
            <div class="col m6 s12">
                <div class="book-list-area">
                    <div class="book-list-head">Books Written</div>
                    <div class="books">
                        <div class="book-item" v-for="b in booksWritten" :key="b.id">
                            <div class="book-item-id">{{b.id}}</div>
                            <div class="book-item-title">{{b.title}}</div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col m6 s12">
                <div class="book-list-area">
                    <div class="book-list-head">Books Owned</div>
                    <div class="books">
                        <div class="book-item" v-for="b in booksOwned" :key="b.id" v-bind:class="{current:b.id==currentBookId}">
                            <div class="book-item-id">{{b.id}}</div>
                            <div class="book-item-title">{{b.title}} ({{b.currentPageId}})</div>
                            <div class="book-item-controls">
                                <i class="material-icons" v-on:click="onOwnedBookDelete(b)" v-if="b.id!==1">delete</i>
                                <i class="material-icons" v-on:click="onMakeCurrent(b)">check</i>
                                <i class="material-icons" v-on:click="onResetBook(b)">refresh</i>
                            </div>
                        </div>
                    </div>
                    <div class="add-book-area">
                        <div class="row">
                            <div class="input-field col s10">
                                <select  v-model="bookToAddId">
                                    <option v-bind:value="l.id" v-for="l in library" :key="l.id">{{l.title}}</option>
                                </select>
                                <label>Add book</label>
                            </div>
                            <div class="col s2">
                                <i class="material-icons add-book" v-on:click="onBookAdd()">add</i>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="col m6 s12">
                <div class="book-list-area">
                    <div class="book-list-head">User Characters</div>
                    <div class="books">
                        <ul class="collapsible">
                            <li v-for="c in characters" :key="c.id">
                                <div class="collapsible-header">
                                    <div class="left-area">
                                        <i class="material-icons text-red">person</i>
                                        <img :src="serviceBaseURL+'cdn/characterImage-'+c.id+'.png'" style="width:20px;height:20px;float:left" @error="onImgError">
                                    </div>
                                    <div class="center-area">
                                        <span class="btn-floating pulse red" v-if="c.id===characterId" style="width:10px;height:10px;"><i class="material-icons">menu</i></span>
                                        ({{c.id}}) {{c.name}} 
                                    </div>
                                    <div class="right-area">
                                        <i class="material-icons" @click.stop="onRemoveCharacter(c)" v-if="c.id!=characterId && characters.length>1">delete</i>
                                        <i class="material-icons" @click.stop="onResetCharacter(c)">refresh</i>
                                        <i v-if="characterId!=c.id" class="material-icons" @click.stop="onMakeActive(c)">check_box</i>
                                    </div>
                                </div>
                                <div class="collapsible-body">
                                    <div style="font-size:10px">(Shift-click) to delete a trait/item</div>
                                    <div style="font-weight:bold">Personality</div>
                                    <span v-for="trait in c.personality" :key="trait._id" class="new badge red" style="margin-right:5px;float:none;display:inline-block" :data-badge-caption="trait.name" @click="onIncDecTrait(c,trait,1,$event)" @contextmenu.prevent="onIncDecTrait(c,trait,-1,$event)">{{trait.level}}</span>
                                    <div style="font-weight:bold;clear:both;margin-top:10px;">Items</div>
                                    <span v-for="item in c.inventory" :key="item._id" class="new badge red" style="margin-right:5px;float:none;display:inline-block" @click="onRemoveItem(c,item,1,$event)" @contextmenu.prevent="onRemoveItem(c,item,-1,$event)"  :data-badge-caption="item.name">{{item.level}}</span>
                                    <a class="waves-effect waves-teal btn-flat right" @click.prevent="onOpenAddNewTraitItem(c)">Add Trait/Item</a>
                                    <div class="row">
                                        <div class="input-field col s12">
                                            <input id="name" type="text" class="validate" v-model="c.name">
                                            <label for="name" class="active">Name</label>
                                        </div>
                                        <!-- <div class="input-field col s12">
                                            <input id="realName" type="text" class="validate" v-model="c.realName">
                                            <label for="realName" class="active">Real Name</label>
                                        </div> -->
                                        <a class="waves-effect waves-teal btn-flat right" @click.prevent="onUpdateCharacter(c)">Save</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="collapsible-header"><i class="material-icons">add</i>Add new character</div>
                                <div class="collapsible-body">
                                    <div class="row">
                                        <div class="input-field col s12">
                                            <input id="name" type="text" class="validate" v-model="newCharacter.name">
                                            <label for="name" class="active">Name</label>
                                        </div>
                                        <!-- <div class="input-field col s12">
                                            <input id="realName" type="text" class="validate" v-model="newCharacter.realName">
                                            <label for="realName" class="active">Real Name</label>
                                        </div> -->
                                        <a class="waves-effect waves-teal btn-flat right" @click.prevent="onAddNewCharacter">Save</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            <div class="col s12">
                <div class="controls">
                    <a class="waves-effect waves-light btn right red" v-on:click="onSave"><i class="material-icons right">save</i>Save</a>
                    <a class="waves-effect waves-light btn right red" v-on:click="onCancel"><i class="material-icons right">cancel</i>Cancel</a>
                </div>
            </div>
        </div>
        <div id="trait-item-add-modal" class="modal">
            <div class="modal-content">
                <div class="row">
                    <div class="col s3">
                        <div class="switch">
                            <label>
                                Trait 
                                <input type="checkbox" v-model="addTraitItemModal.forItem">
                                <span class="lever"></span>
                                Item
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col s4">
                        if the {{addTraitItemModal.forItem?"item":"trait"}} you want to use does not exist in the list below you may add it
                        here
                    </div>
                    <div class="input-field col s6">
                        <input id="new" type="text" v-model="addTraitItemModal.newTraitItem">
                        <label for="new">New {{addTraitItemModal.forItem?'item':'trait'}}</label>
                    </div>
                    <div class="col s2">
                        <a class="btn-floating btn-small waves-effect waves-light red add-trait-item-btn" style="margin-top:25px" @click.prevent="onAddNewTraitItem"><i class="material-icons">add</i></a>
                    </div>

                    <div class="col s12">
                        <select class="browser-default" v-model="addTraitItemModal.traitItemId">
                            <option value="0" disabled selected>{{addTraitItemModal.forItem?'Items':'Traits'}}</option>
                            <option v-for="t in addTraitItemModal.list" v-bind:key="t.id" v-bind:value="t.id">{{t.name}}</option>
                        </select>
                    </div>
                    
                    <div class="input-field col s12">
                        <input id="level" type="text" v-model="addTraitItemModal.level">
                        <label v-if="!addTraitItemModal.forItem" for="level" class="active">Level</label>
                        <label v-if="addTraitItemModal.forItem" for="level" class="active">Amount</label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a href="#!" class="modal-close waves-effect waves-green btn-flat" @click.prevent="onOkAddTraitItem">ok</a>
                <a href="#!" class="modal-close waves-effect waves-green btn-flat">cancel</a>
            </div>
        </div>
    </div>
</template>

<script>
import settings from '../settings.js';
import axios from "axios";
import _ from "underscore";

export default {
    name: "edit-user",
    props: {
        id: {
            type: String,
            default: "-"
        },
    },
    components: {
    },
    computed:{
        forItem() {
            return this.addTraitItemModal.forItem;
        },
        user(){
            return this.$store.state.user;
        },
        disableOrganization(){
            return this.user.organization!='ipplos';
        }
    },
    watch: {
        forItem: function (val) {
            if (val)
                this.addTraitItemModal.list = this.items;
            else
                this.addTraitItemModal.list = this.traits;
        }
    },
    data() {
        return {
            serviceBaseURL: settings.serviceUrl,
            email: '-',
            characterName: '-',
            isAdmin: false,
            isWriter: false,
            imgURL:'',
            bio:'',
            realName:'',
            group:'',
            organization:'',
            password: '-',
            booksOwned:[],
            characters:[],
            booksWritten:[],
            library:[],
            currentBookId:0,
            bookToAddId:1,
            characterId:1,
            items:[],
            traits:[],
            canAddCharacters:false,
            canPurchaseBooks:false,
            newCharacter:{
                name:'',
                realName:''
            },
            addTraitItemModal: {
                actionId: 0,
                forReq: true,
                traitItemId: '0',
                forItem: true,
                list: [],
                newTraitItem: '',
                level: "0",
                character:undefined
            }
        };
    },
    mounted() {
        var that = this;
        this._init=async ()=>{
            this.$root.$emit('ui-blocker',1);
            let response=await axios.post(`${this.serviceBaseURL}library`, {tokenValue: window.localStorage.getItem("token")});
            this.library=response.data.payload;
            response=await axios.post(`${this.serviceBaseURL}getUser`, {tokenValue: window.localStorage.getItem("token"),userId: this.id});
            if (response.data.ok) {
                this.email = response.data.payload.email;
                this.characterName = response.data.payload.characterName;
                this.isAdmin = response.data.payload.isAdmin;
                this.isWriter = response.data.payload.isWriter;
                this.imgURL=response.data.payload.imgURL;
                this.bio=response.data.payload.bio;
                this.realName=response.data.payload.realName;
                this.group=response.data.payload.group;
                this.organization=response.data.payload.organization;
                this.password = response.data.payload.password;
                this.currentBookId=response.data.payload.currentBookId;
                this.characterId=response.data.payload.characterId;
                this.canPurchaseBooks=response.data.payload.canPurchaseBooks?true:false;
                this.canAddCharacters=response.data.payload.canAddCharacters?true:false;
                for (let i=0;i<response.data.payload.books.length;i++){
                    let bookInfo=_.findWhere(this.library,{id:response.data.payload.books[i].id});
                    if (bookInfo){
                        this.booksOwned.push({
                            id:bookInfo.id,
                            title:bookInfo.title,
                            currentPageId:response.data.payload.books[i].currentPageId
                        })
                    }
                    else{
                        this.booksOwned.push({
                            id:response.data.payload.books[i].id,
                            title:"BOOK MISSING FROM LIBRARY"
                        })
                    }
                }
                for (let i=0;i<this.library.length;i++){
                    if (this.library[i].owner===that.email){
                        this.booksWritten.push({
                            id:this.library[i].id,
                            title:this.library[i].title
                        })
                    }
                }
                let traits=(await axios.post(`${this.serviceBaseURL}handleTraitsItems`, {tokenValue: window.localStorage.getItem("token"),action: 4,type:'traits'})).data.payload;
                let items=(await axios.post(`${this.serviceBaseURL}handleTraitsItems`, {tokenValue: window.localStorage.getItem("token"),action: 4,type:'items'})).data.payload;
                this.items=items;
                this.traits=traits;
                this.addTraitItemModal.list=this.items;
                this.characters=(await axios.post(`${this.serviceBaseURL}getCharactersForUser`, {tokenValue: window.localStorage.getItem("token"),email: this.email})).data.payload;
                this.characters.forEach(c => {
                    c.inventory.forEach((i)=>{
                        let item=_.findWhere(items,{id:i.id});
                        if (item)
                            i.name=item.name;
                        else
                            i.name=`Item ${i.id} N/A`
                    });
                    c.personality.forEach((i)=>{
                        let trait=_.findWhere(traits,{id:i.id});
                        if (trait)
                            i.name=trait.name;
                        else
                            i.name=`Trait ${i.id} N/A`
                    });
                });
                this.$nextTick(()=>{
                    M.Collapsible.init(document.querySelector(".collapsible"),{});
                    M.updateTextFields();
                    M.FormSelect.init(document.querySelectorAll('select'), {});
                    M.Modal.init(document.querySelector('#trait-item-add-modal'), {});
                })
                
            } else {
                this.$root.$emit("openErrorModal","Attention required",response.data.message);
            }
            this.$root.$emit('ui-blocker',-1);
        };
        this._init();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.rootScreen = from.fullPath;
        });
    },
    methods: {
        onImgError(e){
            e.currentTarget.src=`${this.serviceBaseURL}cdn/no-image.png`
        },
        upload(elementId){
            var formData = new FormData();
            var imagefile = document.querySelector('#'+elementId);
            formData.append("image", imagefile.files[0]);
            formData.append("filenameToUse",`user-${this.id}`);
            this.imgURL='';
            this.$root.$emit('ui-blocker',1);
            axios.post(`${this.serviceBaseURL}upload`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(r=>{
                if (r.data.ok){
                    this.imgURL=`${this.serviceBaseURL}cdn/${r.data.filename}`;
                }
                this.$root.$emit('ui-blocker',-1);
            });
            return false;
        },
        onRemoveItem(character,item,step,e){
            if (e.shiftKey){
                let deleteAction=async ()=>{
                    this.$root.$emit('ui-blocker',1);
                    let response=await axios.post(`${this.serviceBaseURL}addRemoveItem`,{
                        tokenValue: window.localStorage.getItem("token"),
                        characterId:character.id,
                        itemId:item.id,
                        remove:true});
                    character.inventory.splice(character.inventory.indexOf(item),1);
                    this.$root.$emit('ui-blocker',-1);
                };
                deleteAction();
            }
            let action=async ()=>{
                this.$root.$emit('ui-blocker',1);
                let response=await axios.post(`${this.serviceBaseURL}addRemoveItem`,{
                    tokenValue: window.localStorage.getItem("token"),
                    characterId:character.id,
                    itemId:item.id,
                    levelIncDec:step});
                item.level=parseInt(item.level)+parseInt(step);
                this.$root.$emit('ui-blocker',-1);
            };
            action();


        },
        onIncDecTrait(character,trait,step,e){
            if (e.shiftKey){
                let deleteAction=async ()=>{
                    this.$root.$emit('ui-blocker',1);
                    let response=await axios.post(`${this.serviceBaseURL}updatePersonalityTrait`,{
                        tokenValue: window.localStorage.getItem("token"),
                        characterId:character.id,
                        traitId:trait.id,
                        levelIncDec:0,
                        remove:true
                    });
                    character.personality.splice(character.personality.indexOf(trait),1);
                    this.$root.$emit('ui-blocker',-1);
                };
                deleteAction();
                return;
            }
            let action=async ()=>{
                this.$root.$emit('ui-blocker',1);
                let response=await axios.post(`${this.serviceBaseURL}updatePersonalityTrait`,{
                    tokenValue: window.localStorage.getItem("token"),
                    characterId:character.id,
                    traitId:trait.id,
                    levelIncDec:step});
                trait.level=parseInt(trait.level)+parseInt(step);
                this.$root.$emit('ui-blocker',-1);
            };
            action();
        },
        onOpenAddNewTraitItem(character){
            this.addTraitItemModal.character=character;
            M.Modal.getInstance(document.querySelector("#trait-item-add-modal")).open();
        },
        onAddNewTraitItem(){
            this.$root.$emit('ui-blocker',1);
            axios.post(`${this.serviceBaseURL}handleTraitsItems`, {
                tokenValue: window.localStorage.getItem("token"),
                type: this.addTraitItemModal.forItem ? 'items' : 'traits',
                name: this.addTraitItemModal.newTraitItem,
                action: 1

            }).then(response => {
                if (this.addTraitItemModal.forItem)
                    this.items.push(response.data.payload);
                else
                    this.traits.push(response.data.payload);
                this.addTraitItemModal.traitItemId = response.data.payload.id;
                this.$root.$emit('ui-blocker',-1);
            });
        },
        onOkAddTraitItem(){
            let action=async ()=>{
                this.$root.$emit('ui-blocker',1);
                if (this.addTraitItemModal.forItem){
                    let response=await axios.post(`${this.serviceBaseURL}addRemoveItem`,{
                        tokenValue: window.localStorage.getItem("token"),
                        characterId:this.addTraitItemModal.character.id,
                        itemId:this.addTraitItemModal.traitItemId,
                        levelIncDec:this.addTraitItemModal.level,
                        remove:false
                    });
                    const itemToAdd=_.findWhere(this.items,{id:this.addTraitItemModal.traitItemId});

                    this.addTraitItemModal.character.inventory.push({
                        id:response.data.payload.id,
                        name:itemToAdd.name,
                        level:this.addTraitItemModal.level,
                    });
                }
                else{
                    let response=await axios.post(`${this.serviceBaseURL}updatePersonalityTrait`,{
                        tokenValue: window.localStorage.getItem("token"),
                        characterId:this.addTraitItemModal.character.id,
                        traitId:this.addTraitItemModal.traitItemId,
                        levelIncDec:this.addTraitItemModal.level
                    });
                    const traitToAdd=_.findWhere(this.traits,{id:this.addTraitItemModal.traitItemId});

                    this.addTraitItemModal.character.personality.push({
                        id:response.data.payload.id,
                        name:traitToAdd.name,
                        level:this.addTraitItemModal.level,
                    });
                }
                this.$root.$emit('ui-blocker',-1);
            };
            action();
        },
        onMakeActive(c){
            let action=async ()=>{
                this.$root.$emit('ui-blocker',1);
                let response=await axios.post(`${this.serviceBaseURL}setActiveCharacterForUser`,{tokenValue: window.localStorage.getItem("token"),characterId:c.id,email:this.email});
                this.characterId=c.id;
                this.$root.$emit('ui-blocker',-1);
            };
            action();
        },
        onRemoveCharacter(c){
            let action=async ()=>{
                this.$root.$emit('ui-blocker',1);
                let response=await axios.post(`${this.serviceBaseURL}removeCharacter`,{tokenValue: window.localStorage.getItem("token"),characterId:c.id,email:this.email});
                this.characters.splice(this.characters.indexOf(c),1);
                this.$root.$emit('ui-blocker',-1);
            };
            action();
        },
        onResetCharacter(c){
            let action=async ()=>{
                this.$root.$emit('ui-blocker',1);
                let response=await axios.post(`${this.serviceBaseURL}resetCharacterById`,{tokenValue: window.localStorage.getItem("token"),characterId:c.id,email:this.email});
                c.inventory=[];
                c.personality=[];
                this.$root.$emit('ui-blocker',-1);
            };
            action();
        },
        async onAddNewCharacter(){
            this.$root.$emit('ui-blocker',1);
            let response=await axios.post(`${this.serviceBaseURL}createCharacter`,{tokenValue: window.localStorage.getItem("token"),name:this.newCharacter.name,realName:this.newCharacter.realName,email:this.email});
            this.newCharacter.name='';
            this.newCharacter.realName='';
            this.characters.push(response.data.payload);
            if (this.characters.length==1){
                await axios.post(`${this.serviceBaseURL}setActiveCharacterForUser`,{tokenValue: window.localStorage.getItem("token"),email:this.email,characterId:response.data.payload.id});
                this.characterId=response.data.payload.id;
            }
            this.$root.$emit('ui-blocker',-1);
        },
        onUpdateCharacter(c){
            let action=async ()=>{
                this.$root.$emit('ui-blocker',1);
                let response=await axios.post(`${this.serviceBaseURL}updateCharacterInfoForId`,{tokenValue: window.localStorage.getItem("token"),name:c.name,realName:c.realName,id:c.id});
                this.$root.$emit('ui-blocker',-1);
            };
            action();
        },
        onBookAdd(){
            var that = this;
            this.$root.$emit('ui-blocker',1);
            axios
            .post(`${this.serviceBaseURL}addBook`, {
                tokenValue: window.localStorage.getItem("token"),
                bookId:this.bookToAddId,
                userId:that.id
            })
            .then(response => {
                if (response.data.ok){
                    that.booksOwned.push({
                        id:response.data.payload.id,
                        title:response.data.payload.title
                    })
                }
                this.$root.$emit('ui-blocker',-1);
            });
        },
        onOwnedBookDelete(b){
            var that = this;
            this.$root.$emit('ui-blocker',1);
            axios
            .post(`${this.serviceBaseURL}removeBookFromUser`, {
                tokenValue: window.localStorage.getItem("token"),
                bookId:b.id,
                userId:that.id
            })
            .then(response => {
                if (response.data.ok){
                    that.booksOwned.splice(that.booksOwned.indexOf(b),1);
                }
                this.$root.$emit('ui-blocker',-1);
            });
        },
        onMakeCurrent(b){
            var that = this;
            this.$root.$emit('ui-blocker',1);
            axios
            .post(`${this.serviceBaseURL}updateCurrentBookForUser`, {
                tokenValue: window.localStorage.getItem("token"),
                currentBookId:b.id,
                userId:that.id
            })
            .then(response => {
                if (response.data.ok){
                    that.currentBookId=b.id
                }
                this.$root.$emit('ui-blocker',-1);
            });
        },
        onResetBook(b){
            var that = this;
            this.$root.$emit('ui-blocker',1);
            axios
            .post(`${this.serviceBaseURL}resetBookForUser`, {
                tokenValue: window.localStorage.getItem("token"),
                bookId:b.id,
                email:that.email
            })
            .then(response => {
                b.currentPageId=-1;
                this.$root.$emit('ui-blocker',-1);
            });
        },
        onCancel() {
            this.$router.push(this.rootScreen)
        },
        onSave() {
            var that = this;
            this.$root.$emit('ui-blocker',1);
            axios
                .post(`${this.serviceBaseURL}updateUser`, {
                    tokenValue: window.localStorage.getItem("token"),
                    userId: this.id,
                    email: this.email,
                    isWriter: this.isWriter,
                    isAdmin: this.isAdmin,
                    password: this.password,
                    bio:this.bio,
                    imgURL:this.imgURL,
                    realName:this.realName,
                    group:this.group,
                    organization:this.organization,
                    canPurchaseBooks:this.canPurchaseBooks,
                    canAddCharacters:this.canAddCharacters,
                })
                .then(response => {
                    this.$root.$emit('ui-blocker',-1);
                    that.$router.push(that.rootScreen)
                });
        }
    }
};
</script>

<style lang="less">
.controls {
    margin-top: 20px;

    a {
        margin-left: 10px;
    }

}
.book-list-area{
    padding-top:20px;
    .book-list-head{
        font-weight: bold;
    }
    .books{
        padding-top:10px;
        .book-item{
            display:flex;
            &.current{
                font-weight: bold;
            }
            .book-item-id{
                flex:1 1 25%;
            }
            .book-item-title{
                flex:1 1 65%;
            }
            .book-item-controls{
                flex:1 1 10%;
                cursor:pointer;

                
                color: #999;
                i{
                    font-size: 18px;
                    float:right;
                }
            }
        }
    }
    .add-book-area{
        padding-top:20px;
        .add-book{
            float: right;
            margin-top: 20px;
            font-size: 30px;
            color: #666;
            cursor: pointer;
        }
    }
    .collapsible-header{
        display:flex;
        i{
            margin-right:0px;
        }
        .left-area{
            flex:0 0 50px;
        }
        .center-area{
            flex:1 1 100%;
            text-align: left;
        }
        .right-area{
            flex:0 0 80px;
            text-align: right;
        }
    }
}
</style>
