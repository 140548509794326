var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container admin-list" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s12" }, [
        _c("button", { staticClass: "btn", on: { click: _vm.onSearch } }, [
          _vm._v("Search")
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "btn", on: { click: _vm.onAdd } }, [
          _vm._v("Add")
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col s12", staticStyle: { "margin-top": "20px" } },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col s12",
                staticStyle: { "margin-bottom": "10px" }
              },
              [
                _vm._v(
                  "\n                    Upload image for item (256x256)\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "input-field col s2" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.uploadId,
                    expression: "uploadId"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.uploadId },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.uploadId = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { staticClass: "active" }, [_vm._v("Trait id")])
            ]),
            _vm._v(" "),
            _vm.uploadId
              ? _c("div", { staticClass: "col s10" }, [
                  _c(
                    "form",
                    {
                      staticStyle: { "margin-top": "15px" },
                      attrs: { method: "post", encType: "multipart/form-data" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.upload($event)
                        }
                      }
                    },
                    [
                      _c("input", { attrs: { type: "file" } }),
                      _vm._v(" "),
                      _c("input", { attrs: { type: "submit" } })
                    ]
                  )
                ])
              : _vm._e()
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col s12" },
        [
          _vm.dataLoading
            ? _c("div", { staticClass: "progress" }, [
                _c("div", { staticClass: "indeterminate" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("vuetable", {
            ref: "vuetable",
            class: { loading: _vm.dataLoading },
            attrs: {
              css: _vm.tableCss,
              "api-url": _vm.apiURL,
              "http-fetch": _vm.getData,
              "http-method": "post",
              "load-on-start": false,
              fields: _vm.columns,
              "sort-order": _vm.queryStringData.sort,
              "per-page": _vm.dataPerPage,
              "row-class": _vm.onRowClass,
              "data-path": "items",
              "pagination-path": "payload.pagination"
            },
            on: {
              "vuetable:loading": _vm.loading,
              "vuetable:loaded": _vm.loaded,
              "vuetable:pagination-data": _vm.onPaginationData,
              "VuetableRowAction:action-item": _vm.onRowAction
            },
            scopedSlots: _vm._u([
              {
                key: "languages",
                fn: function(props) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n                        (" +
                          _vm._s(props.rowData.languagesCount) +
                          "/" +
                          _vm._s(_vm.languages.length - 1) +
                          ")\n                        " +
                          _vm._s(props.rowData.languageCodes) +
                          "\n                    "
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col s12" },
        [
          _c("vuetable-pagination", {
            ref: "pagination",
            attrs: { css: _vm.paginationCss },
            on: { "vuetable-pagination:change-page": _vm.onChangePage }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }