var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "container",
          staticStyle: { "background-color": "#fafafa" }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col s12" },
              [
                _c("router-link", { attrs: { to: "/" } }, [_vm._v("home")]),
                _vm._v(" |\r\n        "),
                _vm.isAdmin
                  ? _c("router-link", { attrs: { to: "/users" } }, [
                      _vm._v("users | ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAdmin
                  ? _c("router-link", { attrs: { to: "/traits" } }, [
                      _vm._v("traits | ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAdmin
                  ? _c("router-link", { attrs: { to: "/items" } }, [
                      _vm._v("items | ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAdmin
                  ? _c("router-link", { attrs: { to: "/books" } }, [
                      _vm._v("books | ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.token
                  ? _c("router-link", { attrs: { to: "/signin" } }, [
                      _vm._v("login")
                    ])
                  : _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onLogout($event)
                          }
                        }
                      },
                      [_vm._v("logout")]
                    )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("dialogs-wrapper"),
      _vm._v(" "),
      _c("ui-blocker")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }