<template>
  <div class="container">
    <div class="row">
      <div class="input-field col s12">
        <input id="title" type="text" class="validate" v-model="item.title" />
        <label for="title" class="active">Title</label>
      </div>
      <div class="input-field col s12">
        <input id="organization" type="text" class="validate" v-model="item.organization" :disabled="disableOrganization" />
        <label for="organization" class="active">Organization</label>
      </div>
      <div class="input-field col s12">
        <input id="price" type="text" class="validate" v-model="item.price" />
        <label for="price" class="active">Price</label>
      </div>
      <div class="input-field col s12">
        <input id="abstract" type="text" class="validate" v-model="item.abstract" />
        <label for="abstract" class="active">Abstract</label>
      </div>
      <div class="input-field col s12">
        <input id="authors" type="text" class="validate" v-model="item.authors" />
        <label for="authors" class="active">Authors</label>
      </div>
      <div class="input-field col s12">
        <input id="owner" type="text" class="validate" v-model="item.owner" />
        <label for="owner" class="active">Owner</label>
      </div>
      <div class="input-field col s12">
        <input id="imgURL" type="text" class="validate" v-model="item.imgUrl" />
        <label for="imgURL" class="active">Image URL</label>
      </div>
      <form  method="post" @submit.prevent="upload('img'+item.id)" encType="multipart/form-data">
          <input :id="'img'+item.id" name="foo" type="file" />
          <input type="submit">
      </form>
      <div class="col s12">
        <div class="controls">
          <a class="waves-effect waves-light btn right red" v-on:click="onSave">
            <i class="material-icons right">save</i>Save
          </a>
          <a class="waves-effect waves-light btn right red" v-on:click="onCancel">
            <i class="material-icons right">cancel</i>Cancel
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import settings from "../settings.js";
import axios from "axios";
import _ from "underscore";

export default {
  name: "edit-book",
  props: {
    id: {
      type: String,
      default: "-"
    }
  },
  components: {},
  computed: {
    user() {
      return this.$store.state.user;
    },
    disableOrganization() {
      return this.user.organization != "ipplos";
    }
  },
  watch: {
  },
  data() {
    return {
      serviceBaseURL: settings.serviceUrl,
      item:{}
    };
  },
  mounted() {
    var that = this;
    this._init = async () => {

      let response = await axios.post(`${this.serviceBaseURL}getBook`, {
        tokenValue: window.localStorage.getItem("token"),
        bookId: this.id
      });
      if (response.data.ok) {
        this.item=response.data.info;
        }
        this.$nextTick(() => {
        });
    };
    this._init();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.rootScreen = from.fullPath;
    });
  },
  methods: {
    upload(elementId){
        var formData = new FormData();
        var imagefile = document.querySelector('#'+elementId);
        formData.append("image", imagefile.files[0]);
        axios.post(`${this.serviceBaseURL}upload`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }).then(r=>{
            if (r.data.ok){
                this.item.imgUrl=`${this.serviceBaseURL}cdn/${r.data.filename}`;
            }
        });
        return false;
    },
    onCancel() {
      this.$router.push(this.rootScreen);
    },
    onSave() {
      var that = this;
      this.item.tokenValue=window.localStorage.getItem("token");
      axios
        .post(`${this.serviceBaseURL}updateBookBasicInfo`, this.item)
        .then(response => {
          that.$router.push(that.rootScreen);
        });
    }
  }
};
</script>

<style lang="less">
.controls {
  margin-top: 20px;

  a {
    margin-left: 10px;
  }
}
.book-list-area {
  padding-top: 20px;
  .book-list-head {
    font-weight: bold;
  }
  .books {
    padding-top: 10px;
    .book-item {
      display: flex;
      &.current {
        font-weight: bold;
      }
      .book-item-id {
        flex: 1 1 25%;
      }
      .book-item-title {
        flex: 1 1 65%;
      }
      .book-item-controls {
        flex: 1 1 10%;
        cursor: pointer;

        color: #999;
        i {
          font-size: 18px;
          float: right;
        }
      }
    }
  }
  .add-book-area {
    padding-top: 20px;
    .add-book {
      float: right;
      margin-top: 20px;
      font-size: 30px;
      color: #666;
      cursor: pointer;
    }
  }
  .collapsible-header {
    display: flex;
    i {
      margin-right: 0px;
    }
    .left-area {
      flex: 0 0 40px;
    }
    .center-area {
      flex: 1 1 100%;
      text-align: left;
    }
    .right-area {
      flex: 0 0 80px;
      text-align: right;
    }
  }
}
</style>
