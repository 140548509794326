var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "ui-blocker" }, [_vm._m(0)])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("img", {
        staticStyle: { width: "100px" },
        attrs: { src: require("../assets/pleaseWait.png") }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("Connection to server")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text" }, [
        _vm._v(
          "A read/write operation is in progress. Please do not change page until this message disappears..."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }