var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container admin-list" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s12" }, [
        _c("button", { staticClass: "btn", on: { click: _vm.onSearch } }, [
          _vm._v("Search")
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "btn", on: { click: _vm.onAdd } }, [
          _vm._v("Add")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col s12" },
        [
          _vm.dataLoading
            ? _c("div", { staticClass: "progress" }, [
                _c("div", { staticClass: "indeterminate" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("vuetable", {
            ref: "vuetable",
            class: { loading: _vm.dataLoading },
            attrs: {
              css: _vm.tableCss,
              "api-url": _vm.apiURL,
              "http-fetch": _vm.getData,
              "http-method": "post",
              "load-on-start": false,
              fields: _vm.columns,
              "sort-order": _vm.queryStringData.sort,
              "per-page": _vm.dataPerPage,
              "row-class": _vm.onRowClass,
              "data-path": "libraryItems",
              "pagination-path": "payload.pagination"
            },
            on: {
              "vuetable:loading": _vm.loading,
              "vuetable:loaded": _vm.loaded,
              "vuetable:pagination-data": _vm.onPaginationData,
              "VuetableRowAction:action-item": _vm.onRowAction
            },
            scopedSlots: _vm._u([
              {
                key: "languages",
                fn: function(props) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n                        (" +
                          _vm._s(props.rowData.languagesCount) +
                          "/" +
                          _vm._s(_vm.languages.length - 1) +
                          ")\n                        " +
                          _vm._s(props.rowData.languageCodes) +
                          "\n                    "
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col s12" },
        [
          _c("vuetable-pagination", {
            ref: "pagination",
            attrs: { css: _vm.paginationCss },
            on: { "vuetable-pagination:change-page": _vm.onChangePage }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }