var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-actions" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-smal btn-flat",
        on: {
          click: function($event) {
            return _vm.itemAction("view-item", _vm.rowData, _vm.rowIndex)
          }
        }
      },
      [_c("i", { staticClass: "tiny material-icons" }, [_vm._v("search")])]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-smal btn-flat",
        on: {
          click: function($event) {
            return _vm.itemAction("edit-item", _vm.rowData, _vm.rowIndex)
          }
        }
      },
      [_c("i", { staticClass: "tiny material-icons" }, [_vm._v("edit")])]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-smal btn-flat",
        on: {
          click: function($event) {
            return _vm.itemAction("delete-item", _vm.rowData, _vm.rowIndex)
          }
        }
      },
      [_c("i", { staticClass: "tiny material-icons" }, [_vm._v("delete")])]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }