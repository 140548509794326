module.exports = {
  item: {
    deleted: { id: 2001, message: 'Item deleted', ok: true },
    updated: { id: 2002, message: 'Item updated', ok: true },
    added: { id: 2003, message: 'Item added', ok: true },
    getAll: { id: 2004, message: 'Items retrieved', ok: true },
    getSingle: { id: 2005, message: 'Item retrieved', ok: true },
  },
};

