import "./assets/favicon.png"
import "./assets/logo.png"

import axios from 'axios';
import Vue from 'vue';
import VueRouter from 'vue-router';
import * as ModalDialogs from 'vue-modal-dialogs'
import M from './vendor/materialize/materialize.js'
import settings from './settings.js';
import store from './store';
import resultCodes from '../resultCodes.js';

import App from './app.vue';
import home from './views/home.vue';
import users from './views/users.vue';
import traits from './views/traits.vue';
import items from './views/items.vue';
import books from './views/books.vue';
import book from './views/book.vue';
import user from './views/user.vue';
import login from './views/login.vue';

import VuetableRowActionsNoView from './components/VuetableRowActionsNoView.vue';
import VuetableRowActions from './components/VuetableRowActions.vue';
import UIBlocker from './components/UIBlocker.vue';

import './assets/paixnidagogeio.png';
import './assets/ipplos.png';

Vue.component('vuetable-raw-actions', VuetableRowActions)
Vue.component('vuetable-raw-actions-no-view', VuetableRowActionsNoView)
Vue.component('ui-blocker',UIBlocker);
Vue.use(VueRouter);
Vue.use(ModalDialogs);

const routes=[
  { path: '/', component: home, props: true},
  { path: '/users', component: users, props: true,meta:{auth:true}},
  { path: '/user/:id', component: user, props: true,meta:{auth:true}},
  { path: '/traits', component: traits, props: true,meta:{auth:true}},
  { path: '/items', component: items, props: true,meta:{auth:true}},
  { path: '/books', component: books, props: true,meta:{auth:true}},
  { path: '/book/:id', component: book, props: true,meta:{auth:true}},
  { path: '/signin', component: login, props: true }
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach(async (to, from, next) => {
  let token=window.localStorage.getItem('token');
  let r=await axios.post(`${settings.serviceUrl}tokenExpired`, { tokenValue: token });
  let user=r.data.payload.user;
  if (user && user.organization)
    document.querySelector("#logo").src=`./${user.organization}.png`;
  store.commit('setUser',user);

  if (!to.meta.auth) {
    next();
    return;
  }

  if (!token){
    next('/signin');
    return;
  }

  if (r.data.code <0) {
    localStorage.removeItem('token');
    next('/signin');
    return;
  }

  next();
});

new Vue({
  el: '#app',
  render: h => h(App),
  components: { App },
  router,
  store,
  data: {},
  mounted() {
  },
  methods: {
  },
});