var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.item.title,
              expression: "item.title"
            }
          ],
          staticClass: "validate",
          attrs: { id: "title", type: "text" },
          domProps: { value: _vm.item.title },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.item, "title", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "title" } }, [
          _vm._v("Title")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.item.organization,
              expression: "item.organization"
            }
          ],
          staticClass: "validate",
          attrs: {
            id: "organization",
            type: "text",
            disabled: _vm.disableOrganization
          },
          domProps: { value: _vm.item.organization },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.item, "organization", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "organization" } }, [
          _vm._v("Organization")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.item.price,
              expression: "item.price"
            }
          ],
          staticClass: "validate",
          attrs: { id: "price", type: "text" },
          domProps: { value: _vm.item.price },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.item, "price", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "price" } }, [
          _vm._v("Price")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.item.abstract,
              expression: "item.abstract"
            }
          ],
          staticClass: "validate",
          attrs: { id: "abstract", type: "text" },
          domProps: { value: _vm.item.abstract },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.item, "abstract", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "abstract" } }, [
          _vm._v("Abstract")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.item.authors,
              expression: "item.authors"
            }
          ],
          staticClass: "validate",
          attrs: { id: "authors", type: "text" },
          domProps: { value: _vm.item.authors },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.item, "authors", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "authors" } }, [
          _vm._v("Authors")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.item.owner,
              expression: "item.owner"
            }
          ],
          staticClass: "validate",
          attrs: { id: "owner", type: "text" },
          domProps: { value: _vm.item.owner },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.item, "owner", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "owner" } }, [
          _vm._v("Owner")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.item.imgUrl,
              expression: "item.imgUrl"
            }
          ],
          staticClass: "validate",
          attrs: { id: "imgURL", type: "text" },
          domProps: { value: _vm.item.imgUrl },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.item, "imgUrl", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "imgURL" } }, [
          _vm._v("Image URL")
        ])
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { method: "post", encType: "multipart/form-data" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.upload("img" + _vm.item.id)
            }
          }
        },
        [
          _c("input", {
            attrs: { id: "img" + _vm.item.id, name: "foo", type: "file" }
          }),
          _vm._v(" "),
          _c("input", { attrs: { type: "submit" } })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col s12" }, [
        _c("div", { staticClass: "controls" }, [
          _c(
            "a",
            {
              staticClass: "waves-effect waves-light btn right red",
              on: { click: _vm.onSave }
            },
            [
              _c("i", { staticClass: "material-icons right" }, [
                _vm._v("save")
              ]),
              _vm._v("Save\n        ")
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "waves-effect waves-light btn right red",
              on: { click: _vm.onCancel }
            },
            [
              _c("i", { staticClass: "material-icons right" }, [
                _vm._v("cancel")
              ]),
              _vm._v("Cancel\n        ")
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }