var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s12" }, [
        _vm._v("\r\n                User Id: " + _vm._s(_vm.id) + " "),
        _c("br"),
        _c("br")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.realName,
              expression: "realName"
            }
          ],
          staticClass: "validate",
          attrs: { id: "realName", type: "text" },
          domProps: { value: _vm.realName },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.realName = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "realName" } }, [
          _vm._v("Name")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.organization,
              expression: "organization"
            }
          ],
          staticClass: "validate",
          attrs: {
            id: "organization",
            type: "text",
            disabled: _vm.disableOrganization
          },
          domProps: { value: _vm.organization },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.organization = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "organization" } }, [
          _vm._v("Organization")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.group,
              expression: "group"
            }
          ],
          staticClass: "validate",
          attrs: { id: "group", type: "text" },
          domProps: { value: _vm.group },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.group = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "group" } }, [
          _vm._v("Group")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email"
            }
          ],
          staticClass: "validate",
          attrs: { id: "email", type: "email" },
          domProps: { value: _vm.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.email = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "email" } }, [
          _vm._v("Email")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password"
            }
          ],
          staticClass: "validate",
          attrs: { id: "password", type: "text" },
          domProps: { value: _vm.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "password" } }, [
          _vm._v("Password")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12 m2" }, [
        _c("img", {
          staticStyle: { width: "80%" },
          attrs: { src: _vm.imgURL },
          on: { error: _vm.onImgError }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12 m10" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.imgURL,
              expression: "imgURL"
            }
          ],
          staticClass: "validate",
          attrs: { id: "imgURL", type: "text" },
          domProps: { value: _vm.imgURL },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.imgURL = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "imgURL" } }, [
          _vm._v("Image URL (700x600)")
        ])
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "col s12",
          attrs: { method: "post", encType: "multipart/form-data" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.upload("img" + _vm.id)
            }
          }
        },
        [
          _c("input", {
            attrs: { id: "img" + _vm.id, name: "foo", type: "file" }
          }),
          _vm._v(" "),
          _c("input", { attrs: { type: "submit" } })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "input-field col s12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.bio,
              expression: "bio"
            }
          ],
          staticClass: "validate",
          attrs: { id: "bio", type: "text" },
          domProps: { value: _vm.bio },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.bio = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "active", attrs: { for: "bio" } }, [
          _vm._v("Bio")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col s3" }, [
        _c("div", { staticClass: "switch" }, [
          _c("label", [
            _vm._v("\r\n                    Writer\r\n                    "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isWriter,
                  expression: "isWriter"
                }
              ],
              staticClass: "red",
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.isWriter)
                  ? _vm._i(_vm.isWriter, null) > -1
                  : _vm.isWriter
              },
              on: {
                change: function($event) {
                  var $$a = _vm.isWriter,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isWriter = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isWriter = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isWriter = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "lever" }),
            _vm._v("\r\n                    -\r\n                ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col s3" }, [
        _c("div", { staticClass: "switch" }, [
          _c("label", [
            _vm._v("\r\n                    Admin\r\n                    "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isAdmin,
                  expression: "isAdmin"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.isAdmin)
                  ? _vm._i(_vm.isAdmin, null) > -1
                  : _vm.isAdmin
              },
              on: {
                change: function($event) {
                  var $$a = _vm.isAdmin,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isAdmin = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isAdmin = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isAdmin = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "lever" }),
            _vm._v("\r\n                    -\r\n                ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col s3" }, [
        _c("div", { staticClass: "switch" }, [
          _c("label", [
            _vm._v(
              "\r\n                    Can purchase books\r\n                    "
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.canPurchaseBooks,
                  expression: "canPurchaseBooks"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.canPurchaseBooks)
                  ? _vm._i(_vm.canPurchaseBooks, null) > -1
                  : _vm.canPurchaseBooks
              },
              on: {
                change: function($event) {
                  var $$a = _vm.canPurchaseBooks,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.canPurchaseBooks = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.canPurchaseBooks = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.canPurchaseBooks = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "lever" }),
            _vm._v("\r\n                    -\r\n                ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col s3" }, [
        _c("div", { staticClass: "switch" }, [
          _c("label", [
            _vm._v(
              "\r\n                    Can add characters\r\n                    "
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.canAddCharacters,
                  expression: "canAddCharacters"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.canAddCharacters)
                  ? _vm._i(_vm.canAddCharacters, null) > -1
                  : _vm.canAddCharacters
              },
              on: {
                change: function($event) {
                  var $$a = _vm.canAddCharacters,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.canAddCharacters = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.canAddCharacters = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.canAddCharacters = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "lever" }),
            _vm._v("\r\n                    -\r\n                ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col m6 s12" }, [
        _c("div", { staticClass: "book-list-area" }, [
          _c("div", { staticClass: "book-list-head" }, [
            _vm._v("Books Written")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "books" },
            _vm._l(_vm.booksWritten, function(b) {
              return _c("div", { key: b.id, staticClass: "book-item" }, [
                _c("div", { staticClass: "book-item-id" }, [
                  _vm._v(_vm._s(b.id))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "book-item-title" }, [
                  _vm._v(_vm._s(b.title))
                ])
              ])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col m6 s12" }, [
        _c("div", { staticClass: "book-list-area" }, [
          _c("div", { staticClass: "book-list-head" }, [_vm._v("Books Owned")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "books" },
            _vm._l(_vm.booksOwned, function(b) {
              return _c(
                "div",
                {
                  key: b.id,
                  staticClass: "book-item",
                  class: { current: b.id == _vm.currentBookId }
                },
                [
                  _c("div", { staticClass: "book-item-id" }, [
                    _vm._v(_vm._s(b.id))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "book-item-title" }, [
                    _vm._v(
                      _vm._s(b.title) + " (" + _vm._s(b.currentPageId) + ")"
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "book-item-controls" }, [
                    b.id !== 1
                      ? _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            on: {
                              click: function($event) {
                                return _vm.onOwnedBookDelete(b)
                              }
                            }
                          },
                          [_vm._v("delete")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        on: {
                          click: function($event) {
                            return _vm.onMakeCurrent(b)
                          }
                        }
                      },
                      [_vm._v("check")]
                    ),
                    _vm._v(" "),
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        on: {
                          click: function($event) {
                            return _vm.onResetBook(b)
                          }
                        }
                      },
                      [_vm._v("refresh")]
                    )
                  ])
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "add-book-area" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "input-field col s10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.bookToAddId,
                        expression: "bookToAddId"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.bookToAddId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._l(_vm.library, function(l) {
                    return _c(
                      "option",
                      { key: l.id, domProps: { value: l.id } },
                      [_vm._v(_vm._s(l.title))]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("label", [_vm._v("Add book")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s2" }, [
                _c(
                  "i",
                  {
                    staticClass: "material-icons add-book",
                    on: {
                      click: function($event) {
                        return _vm.onBookAdd()
                      }
                    }
                  },
                  [_vm._v("add")]
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col m6 s12" }, [
        _c("div", { staticClass: "book-list-area" }, [
          _c("div", { staticClass: "book-list-head" }, [
            _vm._v("User Characters")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "books" }, [
            _c(
              "ul",
              { staticClass: "collapsible" },
              [
                _vm._l(_vm.characters, function(c) {
                  return _c("li", { key: c.id }, [
                    _c("div", { staticClass: "collapsible-header" }, [
                      _c("div", { staticClass: "left-area" }, [
                        _c("i", { staticClass: "material-icons text-red" }, [
                          _vm._v("person")
                        ]),
                        _vm._v(" "),
                        _c("img", {
                          staticStyle: {
                            width: "20px",
                            height: "20px",
                            float: "left"
                          },
                          attrs: {
                            src:
                              _vm.serviceBaseURL +
                              "cdn/characterImage-" +
                              c.id +
                              ".png"
                          },
                          on: { error: _vm.onImgError }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "center-area" }, [
                        c.id === _vm.characterId
                          ? _c(
                              "span",
                              {
                                staticClass: "btn-floating pulse red",
                                staticStyle: { width: "10px", height: "10px" }
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("menu")
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\r\n                                        (" +
                            _vm._s(c.id) +
                            ") " +
                            _vm._s(c.name) +
                            " \r\n                                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "right-area" }, [
                        c.id != _vm.characterId && _vm.characters.length > 1
                          ? _c(
                              "i",
                              {
                                staticClass: "material-icons",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.onRemoveCharacter(c)
                                  }
                                }
                              },
                              [_vm._v("delete")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.onResetCharacter(c)
                              }
                            }
                          },
                          [_vm._v("refresh")]
                        ),
                        _vm._v(" "),
                        _vm.characterId != c.id
                          ? _c(
                              "i",
                              {
                                staticClass: "material-icons",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.onMakeActive(c)
                                  }
                                }
                              },
                              [_vm._v("check_box")]
                            )
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "collapsible-body" },
                      [
                        _c("div", { staticStyle: { "font-size": "10px" } }, [
                          _vm._v("(Shift-click) to delete a trait/item")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("Personality")
                        ]),
                        _vm._v(" "),
                        _vm._l(c.personality, function(trait) {
                          return _c(
                            "span",
                            {
                              key: trait._id,
                              staticClass: "new badge red",
                              staticStyle: {
                                "margin-right": "5px",
                                float: "none",
                                display: "inline-block"
                              },
                              attrs: { "data-badge-caption": trait.name },
                              on: {
                                click: function($event) {
                                  return _vm.onIncDecTrait(c, trait, 1, $event)
                                },
                                contextmenu: function($event) {
                                  $event.preventDefault()
                                  return _vm.onIncDecTrait(c, trait, -1, $event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(trait.level))]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              clear: "both",
                              "margin-top": "10px"
                            }
                          },
                          [_vm._v("Items")]
                        ),
                        _vm._v(" "),
                        _vm._l(c.inventory, function(item) {
                          return _c(
                            "span",
                            {
                              key: item._id,
                              staticClass: "new badge red",
                              staticStyle: {
                                "margin-right": "5px",
                                float: "none",
                                display: "inline-block"
                              },
                              attrs: { "data-badge-caption": item.name },
                              on: {
                                click: function($event) {
                                  return _vm.onRemoveItem(c, item, 1, $event)
                                },
                                contextmenu: function($event) {
                                  $event.preventDefault()
                                  return _vm.onRemoveItem(c, item, -1, $event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.level))]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "waves-effect waves-teal btn-flat right",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.onOpenAddNewTraitItem(c)
                              }
                            }
                          },
                          [_vm._v("Add Trait/Item")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "input-field col s12" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: c.name,
                                  expression: "c.name"
                                }
                              ],
                              staticClass: "validate",
                              attrs: { id: "name", type: "text" },
                              domProps: { value: c.name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(c, "name", $event.target.value)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { staticClass: "active", attrs: { for: "name" } },
                              [_vm._v("Name")]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "waves-effect waves-teal btn-flat right",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onUpdateCharacter(c)
                                }
                              }
                            },
                            [_vm._v("Save")]
                          )
                        ])
                      ],
                      2
                    )
                  ])
                }),
                _vm._v(" "),
                _c("li", [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "collapsible-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "input-field col s12" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newCharacter.name,
                              expression: "newCharacter.name"
                            }
                          ],
                          staticClass: "validate",
                          attrs: { id: "name", type: "text" },
                          domProps: { value: _vm.newCharacter.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newCharacter,
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { staticClass: "active", attrs: { for: "name" } },
                          [_vm._v("Name")]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "waves-effect waves-teal btn-flat right",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onAddNewCharacter($event)
                            }
                          }
                        },
                        [_vm._v("Save")]
                      )
                    ])
                  ])
                ])
              ],
              2
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col s12" }, [
        _c("div", { staticClass: "controls" }, [
          _c(
            "a",
            {
              staticClass: "waves-effect waves-light btn right red",
              on: { click: _vm.onSave }
            },
            [
              _c("i", { staticClass: "material-icons right" }, [
                _vm._v("save")
              ]),
              _vm._v("Save")
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "waves-effect waves-light btn right red",
              on: { click: _vm.onCancel }
            },
            [
              _c("i", { staticClass: "material-icons right" }, [
                _vm._v("cancel")
              ]),
              _vm._v("Cancel")
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal", attrs: { id: "trait-item-add-modal" } }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col s3" }, [
            _c("div", { staticClass: "switch" }, [
              _c("label", [
                _vm._v(
                  "\r\n                                Trait \r\n                                "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.addTraitItemModal.forItem,
                      expression: "addTraitItemModal.forItem"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.addTraitItemModal.forItem)
                      ? _vm._i(_vm.addTraitItemModal.forItem, null) > -1
                      : _vm.addTraitItemModal.forItem
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.addTraitItemModal.forItem,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.addTraitItemModal,
                              "forItem",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.addTraitItemModal,
                              "forItem",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.addTraitItemModal, "forItem", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "lever" }),
                _vm._v(
                  "\r\n                                Item\r\n                            "
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col s4" }, [
            _vm._v(
              "\r\n                        if the " +
                _vm._s(_vm.addTraitItemModal.forItem ? "item" : "trait") +
                " you want to use does not exist in the list below you may add it\r\n                        here\r\n                    "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-field col s6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.addTraitItemModal.newTraitItem,
                  expression: "addTraitItemModal.newTraitItem"
                }
              ],
              attrs: { id: "new", type: "text" },
              domProps: { value: _vm.addTraitItemModal.newTraitItem },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.addTraitItemModal,
                    "newTraitItem",
                    $event.target.value
                  )
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "new" } }, [
              _vm._v(
                "New " +
                  _vm._s(_vm.addTraitItemModal.forItem ? "item" : "trait")
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s2" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn-floating btn-small waves-effect waves-light red add-trait-item-btn",
                staticStyle: { "margin-top": "25px" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onAddNewTraitItem($event)
                  }
                }
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("add")])]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s12" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.addTraitItemModal.traitItemId,
                    expression: "addTraitItemModal.traitItemId"
                  }
                ],
                staticClass: "browser-default",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.addTraitItemModal,
                      "traitItemId",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c(
                  "option",
                  { attrs: { value: "0", disabled: "", selected: "" } },
                  [
                    _vm._v(
                      _vm._s(_vm.addTraitItemModal.forItem ? "Items" : "Traits")
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.addTraitItemModal.list, function(t) {
                  return _c(
                    "option",
                    { key: t.id, domProps: { value: t.id } },
                    [_vm._v(_vm._s(t.name))]
                  )
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-field col s12" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.addTraitItemModal.level,
                  expression: "addTraitItemModal.level"
                }
              ],
              attrs: { id: "level", type: "text" },
              domProps: { value: _vm.addTraitItemModal.level },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.addTraitItemModal, "level", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            !_vm.addTraitItemModal.forItem
              ? _c(
                  "label",
                  { staticClass: "active", attrs: { for: "level" } },
                  [_vm._v("Level")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.addTraitItemModal.forItem
              ? _c(
                  "label",
                  { staticClass: "active", attrs: { for: "level" } },
                  [_vm._v("Amount")]
                )
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "a",
          {
            staticClass: "modal-close waves-effect waves-green btn-flat",
            attrs: { href: "#!" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onOkAddTraitItem($event)
              }
            }
          },
          [_vm._v("ok")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "modal-close waves-effect waves-green btn-flat",
            attrs: { href: "#!" }
          },
          [_vm._v("cancel")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "collapsible-header" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
      _vm._v("Add new character")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }