<template>
    <div class="container admin-list">
        <div class="row">
            <div class="input-field col s6">
                <input type="text" v-model="queryStringData.search.email">
                <label class="active">Email</label>
            </div>
            <div class="input-field col s6">
                <input type="text" v-model="queryStringData.search.group">
                <label class="active">Group</label>
            </div>
            <div class="col s12">
                <button class="btn" @click="onSearch">Search</button>
                <button class="btn" @click="onAdd">Add</button>
            </div>
        </div>
        <div class="row">
            <div class="col s12">
                <div class="progress" v-if="dataLoading">
                    <div class="indeterminate"></div>
                </div>
                <vuetable ref="vuetable" :class="{loading:dataLoading}"
                    :css="tableCss"
                    :api-url="apiURL"
                    :http-fetch="getData"
                    http-method="post"
                    :load-on-start="false"
                    :fields="columns"
                    :sort-order="queryStringData.sort"
                    :per-page="dataPerPage"
                    :row-class="onRowClass"
                    @vuetable:loading="loading"
                    @vuetable:loaded="loaded"
                    data-path="payload"
                    pagination-path="payload.pagination"
                    @vuetable:pagination-data="onPaginationData"
                    @VuetableRowAction:action-item="onRowAction">
                    <template slot="languages" scope="props">
                        <div>
                            ({{props.rowData.languagesCount}}/{{languages.length-1}})
                            {{props.rowData.languageCodes}}
                        </div>
                    </template> 
                </vuetable>
                
            </div>
            <div class="col s12">
            <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="paginationCss">
            </vuetable-pagination>
            </div>
            
        </div>
        <!-- <UIBlocker ref="UIBlocker"></UIBlocker> -->
    </div>
    
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';
import settings from '../settings';

import Confirm from '../components/dialogs/confirm.vue';
import EditSingleValue from '../components/dialogs/editSingleValue.vue';
import Alert from '../components/dialogs/alert.vue';
import { create } from 'vue-modal-dialogs'

import Vuetable from '../../../node_modules/vuetable-2/src/components/Vuetable.vue';
import VuetablePagination from '../../../node_modules/vuetable-2/src/components/VuetablePagination.vue';

// import VueSelect from 'vue-select'
// import UIBlocker from '../general/UIBlocker.vue';
// import '../../../node_modules/vue-select/dist/vue-select.css'


const confirm = create(Confirm, 'title', 'content')
const editSingleValue= create(EditSingleValue, 'title', 'content','value')
const alert = create(Alert, 'title', 'content')

const itemName='user';
const itemNamePlural='users';
const searchParams={
    email:'',
    group:''
};
const mongoQuery={
    email:'^TEXT',
    group:'^TEXT'
};
const intialSort=[{
    field:'id',
    direction:'DESC'
}]
const tableColumns=[
    {
        name: 'imgURL',
        title:'Icon',
        // titleClass: 'center aligned',
        // dataClass: 'center aligned',
        callback: (v)=>{
            return `<img src='${v}' style="width:60px" onerror="this.onerror=null;this.src='${settings.serviceUrl}cdn/no-image.png';"/>`
        }
    },
    {
        name: 'id',
        title:'Id'
        // titleClass: 'center aligned',
        // dataClass: 'center aligned',
        // callback: 'gender'
    },
    {
        name: 'realName',
        title:'Name',
        sortField: 'realName',
    },
    {
        name: 'group',
        title:'Group',
        sortField: 'group',
    },
    {
        name: 'organization',
        title:'Organization',
        sortField: 'organization',
    },
    {
        name: 'email',
        title:'Email',
        sortField: 'email',
    },
    {
        name: 'character',
        title:'Active Character'
    },
    {
        name: 'isWriter',
        title:'Writer',
        sortField: 'isWriter',
        callback:(v)=>{
            if (v)
                return `<i class="material-icons">check</i>`
            return `<i class="material-icons">clear</i>`
        }
    },
    {
        name: 'isAdmin',
        title:'Admin',
        sortField: 'isAdmin',
        callback:(v)=>{
            if (v)
                return `<i class="material-icons">check</i>`
            return `<i class="material-icons">clear</i>`
        }
    },
    {
        name: '__component:vuetable-raw-actions-no-view',  
        title: 'Actions',
        // titleClass: 'center aligned',
        // dataClass: 'center aligned'
    }
];
const deleteConfirmationField='id';
const defaultNewItem={
    email:'',
    password:'',
    roleId:0
}

export default {
    data(){
        return {
            routeURL:'/'+itemNamePlural,
            editURL:'/'+itemName+'/',
            apiURL:`${settings.serviceUrl}getUsers`,
            deleteAPIURL:`${settings.serviceUrl}deleteUser`,
            createAPIURL:`${settings.serviceUrl}${itemName}/add`,
            queryStringData:{
                search:searchParams,
                sort:intialSort
            },
            columns:tableColumns,
            paginationCss:{
                icons:{
                    first: 'material-icons first_page',
                    prev: 'material-icons chevron_left',
                    next: 'material-icons chevron_right',
                    last: 'material-icons last_page',
                },
                pageClass: 'page-item',
                activeClass:'active'
            },
            tableCss:{
                tableClass:'striped responsive-table',
                ascendingIcon:  {
                    params:['material-icons right','arrow_drop_down']
                },
                descendingIcon: {
                    params:['material-icons right','arrow_drop_up']
                },
                renderIcon:function(classes){
                    if (classes[1])
                        return `<i class="${classes[0]} ${classes[1].params[0]}"> ${classes[1].params[1]}</i>`;
                    else
                        return `<i class="${classes[0]}"></i>`;
                }
            },
            dataPerPage:settings.dataPerPage,
            dataLoading:false, 
            selectedElementId:undefined
        };
    },
    components:{
        Vuetable,
        VuetablePagination
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            // if (vm.$store.state.adminEditPageId){
            //     vm.selectedElementId=vm.$store.state.adminEditPageId;
            //     vm.$store.commit('setAdminEditPageId',undefined);
            // }
            if (vm.$route.query.id){
                vm.selectedElementId=vm.$route.query.id;
                //vm.$store.commit('setAdminEditPageId',undefined);
            }
            if (vm.$route.query.search){
                let searchArgs=vm.$route.query.search.split('|');
                for (let sa of searchArgs){
                    if (sa){
                        let qArgs=sa.split(',');
                        vm.queryStringData.search[qArgs[0]]=qArgs[1];
                    }
                }
            }
            if (vm.$route.query.sort){
                let sortArgs=vm.$route.query.sort.split('|');
                for (let sa of sortArgs){
                    if (sa){
                        let sArgs=sa.split(',');
                        vm.queryStringData.sort.push({
                            field:sArgs[0],
                            direction:sArgs[1]
                        });
                    }
                }
            }
        })
        
    },
    mounted(){
        this.updateURLWithSearchAndSortData=()=>{
            let sort=this.$refs.vuetable.getSortParam();
            let searchParams="";
            for (let s in this.queryStringData.search)
                if (this.queryStringData.search[s])
                    searchParams+=`|${s},${this.queryStringData.search[s]}`;
            if (searchParams)
                searchParams=searchParams.substr(0);

            let sortParams="";
            for (let s of this.$refs.vuetable.sortOrder)
                sortParams+=`|${s.field},${s.direction}`;
            if (sortParams)
                sortParams=sortParams.substr(0);
            
            this.$router.push({path:this.routeURL,query:{search:searchParams,sort:sortParams,page:this.$refs.vuetable.currentPage}})
        };
        if (this.$route.query.page){
            this.$refs.vuetable.currentPage =parseInt(this.$route.query.page);
        }
        this.$refs.vuetable.reload();
    },
    methods:{
        getData(apiUrl, httpOptions){
            let sort=[];
            for (let s of this.$refs.vuetable.sortOrder)
                sort.push({field:s.sortField,direction:s.direction});
            let search={};
            for (let key in this.queryStringData.search){
                if (this.queryStringData.search[key]!==undefined && this.queryStringData.search[key]!=''){
                    search[key]=mongoQuery[key].replace('TEXT',this.queryStringData.search[key])
                }
            }
            return axios.post(apiUrl, {
                tokenValue:window.localStorage.getItem("token"),
                item:search,
                sort,
                itemExtra:{},
                page:{
                    page:httpOptions.params.page,
                    perPage:httpOptions.params.per_page,
                }
            });
        },
        loading(){
            this.dataLoading=true;
        },
        loaded(){
            if (this.selectedElementId){
                if (document.querySelector(".selected"))
                    document.querySelector(".selected").scrollIntoView();
            }
            this.dataLoading=false;
            
            this.updateURLWithSearchAndSortData();
        },
        onRowClass(data,index){
            if (this.selectedElementId){
                if (data.id==this.selectedElementId){
                    return "selected";
                }
            }
            return "";
        },
        async onRowAction(action,data){
            const self=this;
            if (data.action==='edit-item'){
                //this.$store.commit('setAdminEditPageId',data.data.id);
                this.$router.push(this.editURL+data.data.id);
            }
            if (data.action==='delete-item'){
                let proceed=await confirm('Deleting item', `Are you sure you want to delete item  ${data.data[deleteConfirmationField]}?`);
                if (proceed){
                    let response=await axios.post(`${this.deleteAPIURL}`,{
                        tokenValue:window.localStorage.getItem("token"),
                        userId:data.data.id
                    });
                    this.$refs.vuetable.refresh();
                }
            }
        },
        onPaginationData (paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage (page) {
            //this.$router.push({ path: '/danae-ticket-classes', query: this.query })
            this.$refs.vuetable.changePage(page)
        },
        onSearch(){
            //for(var k in this.search) this.apiURLParams[k]=this.search[k];
            //this.updateQuery(1,this.search);
            //this.$router.push({ path: '/danae-ticket-classes', query: this.query })
            this.$refs.vuetable.refresh();
        },
        async onAdd(){
            let result=await editSingleValue('Add new user email',`Please provide the new user email`,'');
            if (result){
                let response=await axios.post(`${settings.serviceUrl}register`, {
                    email: result,
                    password: '1234',
                    isWriter: false,
                    isAdmin: false,
                    organization:this.$store.state.user.organization
                });
            
                if (!response.data.ok){
                    if (response.data.code==-2)
                        await alert('Email exists',`The email ${result} already exists in the database`);
                    else
                        await alert('Server problem',response.data.message);
                    return;
                }
                let newId=response.data.payload;
                this.$router.push(this.editURL+response.data.payload.user._id);
            }
        }
    }
}
</script>

<style lang="less">
td{
    padding:0px;
}
</style>
