<template>
<div>
  <div class="container" style="background-color:#fafafa;">
    <div class="row">
      <div class="col s12">
        <router-link to="/">home</router-link> |
        <router-link v-if="isAdmin" to="/users">users | </router-link>
        <router-link v-if="isAdmin" to="/traits">traits | </router-link>
        <router-link v-if="isAdmin" to="/items">items | </router-link>
        <router-link v-if="isAdmin" to="/books">books | </router-link>
        <router-link v-if="!token" to="/signin">login</router-link>
        <a href="" v-else @click.prevent="onLogout">logout</a>
      </div>
    </div>
  </div>
  <router-view></router-view>
  <dialogs-wrapper></dialogs-wrapper>
  <ui-blocker></ui-blocker>
</div>
  
</template>

<style lang="less">
.router-link-active{
  text-decoration: none;
  color:black;
}
.dialog-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(0,0,0,.33);
    .dialog-content {
      min-width: 240px;
      text-align: center;
      font-size: 16px;
      margin: 0 20px;
      padding: 20px 30px;
      border: 1px solid #ebeef5;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      color: #303133;
      header{
        font-weight: bold;
        background-color: #ccc;
        padding: 10px;
        margin-bottom: 10px;
      }
    }

}
</style>

<script>
export default {
  data () {
    return {
      token:localStorage.getItem('token')
    }
  },
  computed:{
    isAdmin(){
      return this.$store.state.user.isAdmin;
    }
  },
  mounted(){
    this.$root.$on('loggedIn',()=>{
      this.token=localStorage.getItem('token');
    })
  },
  methods:{
    async onLogout(){
      localStorage.removeItem('token');
      this.token=undefined;
      this.$router.push('/signin');
    }
  }
}
</script>

