import { render, staticRenderFns } from "./book.vue?vue&type=template&id=6a75f5b6&"
import script from "./book.vue?vue&type=script&lang=js&"
export * from "./book.vue?vue&type=script&lang=js&"
import style0 from "./book.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\projects\\rpg.server\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6a75f5b6')) {
      api.createRecord('6a75f5b6', component.options)
    } else {
      api.reload('6a75f5b6', component.options)
    }
    module.hot.accept("./book.vue?vue&type=template&id=6a75f5b6&", function () {
      api.rerender('6a75f5b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/views/book.vue"
export default component.exports